@import '~antd/dist/antd.css';

:root {
  --primary1: #06F6CF;
  --primary2: #AFFDF0;
  --primary3: #04BC9E;
  --dark: #555;
}

::selection {
  background-color: var(--primary3);
}

html {
  overflow: hidden;
}

body {
  background-color: var(--primary1);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

body.init {
  overflow: hidden;
  height: 100%;
}

.noselect {
  user-select: none;
}

.title {
  font-weight: bold;
  margin-top: 10vh;
  transition: all .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: var(--dark)
}

.title.init {
  transform: scale(2);
  margin-top: 20vh;
}

.title > span {
  padding-right: 20px;
}

.subtitle {
  opacity: 0;
  font-size: 0px;
  color: var(--dark);
  transition: all .5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle.init {
  opacity: 1;
  font-size: 24px;
  transform: translateY(40vh);
}

.subtitle > span {
  padding-left: 10px;
}

.react-clock__face {
  background-color: #fff !important;
  border-color: #fff !important;
}

.react-clock__hand__body
{
  background-color: var(--primary1) !important;
}

.react-clock__second-hand__body {
  background-color: var(--primary3) !important;
}

.calendar-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 30px;
}

.month-picker {
  transition: all .5s;
}

.month-picker.init {
  transform: scale(1.5) translateY(10vh);
}

.calendar-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-arrows.calendar-arrows-small {
  margin-top: 30px;
}

.calendar-arrow {
  margin: 0px 20px;
  color: #fff;
  cursor: pointer;
  transition: all .5;
}

.calendar-arrow.init {
  display: none;
}

.calendar-arrow.calendar-arrow-small {
  display: none;
}

.calendar {
  top: 20vh;
  width: 600px;
  max-width: 90%;
  margin-top: 20px;
  transition: all .5s;
}

.calendar.init {
  transform: translateY(40vh);
  opacity: 0.3;
}

.calendar.init .calendar-day {
  cursor: inherit;
}

.calendar-header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.calendar-weekday {
  flex-basis: 14%;
  background-color: var(--dark);
  align-items: center;
  justify-content: center;
  color: #fff;
  display: flex;
  height: 6vh;
  max-height: 50px;
  min-height: 20px;
  border-bottom: solid 2px var(--primary1);
}

.calendar-weekday:first-child {
  border-top-left-radius: 10px;
  border-left: solid 1px var(--primary1);
}

.calendar-weekday:last-child {
  border-top-right-radius: 10px;
  border-right: solid 1px var(--primary1);
}

.calendar-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.calendar-day {
  flex-basis: 14%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 10vh;
  max-height: 75px;
  min-height: 50px;
  cursor: pointer;
  border: solid 1px var(--primary1);
  min-width: 50px;
}

.calendar-day.today:not(.grey-text) {
  background-color: var(--primary2);
}

.calendar-day:hover {
  background-color: #eee;
}

.calendar-day.today:not(.grey-text):hover {
  background-color: var(--primary1);
}

.calendar-day:nth-child(36) {
  border-bottom-left-radius: 10px;
}

.calendar-day:last-child {
  border-bottom-right-radius: 10px;
}

.calendar-day-number {
  font-size: 20px;
}

.calendar-day-info {
  font-size: 12px;
  padding: 2px 6px;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.calendar-day:not(.grey-text) .calendar-day-highlight {
  color: var(--primary1);
  font-weight: bold;
}

.calendar-day:not(.grey-text) .calendar-day-main {
  background-color: var(--primary2);
  border-radius: 999px;
}

.calendar-day.today:not(.grey-text) .calendar-day-highlight {
  color: #fff;
}

.calendar-day.today:not(.grey-text) .calendar-day-main {
  background-color: #fff;
}

.grey-text {
  color: #bbb;
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover,
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  background-color: var(--primary1);
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: var(--primary3);
}

.ant-calendar-month-panel-month:hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title:hover {
  background-color: var(--primary2)
}

.ant-calendar-header a:hover,
.ant-dropdown-menu-item-selected {
  color: var(--primary3);
}

.ant-input:hover,
.ant-input:focus {
  border-color: var(--primary3);
}

.toolbar {
  position: fixed;
  bottom: 40px;
  right: 40px;
  opacity: 1;
  transition: all .5s;
}

.toolbar.init {
  opacity: 0;
}

.toolbar-icon {
  margin: 5px;
  cursor: pointer;
}

.toolbar-icon:hover {
  color: #000 !important;
}

.menu-title {
  color: #bbb;
  font-size: 10px;
  padding-left: 10px;
}

.menu-title:hover {
  background-color: #fff !important;
  cursor: default;
}

.detail-item,
.detail-item > span {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .calendar-arrow {
    display: none;
  }

  .calendar-arrow.calendar-arrow-small.init {
    display: none;
  }

  .calendar-arrow.calendar-arrow-small {
    display: block;
  }
}

@media only screen and (max-width: 550px) {
  .title {
    font-size: 24px;
    flex-direction: column;
  }

  .title > span {
    padding-right: 0;
    padding-bottom: 15px;
  }

  .title.init {
    transform: scale(1.5);
    margin-top: 10vh;
  }

  .calendar {
    transform: scale(0.55);
    transform-origin: 50% 0;
  }

  .calendar.init {
    transform: translateY(40vh) scale(0.55);
  }

  .calendar-arrow.calendar-arrow-small {
    margin-left: 10px;
    margin-right: 10px;
  }
}
